<template>
  <div class="bg-gray-100">
    <div id="imgHandler" class="hidden">
    </div>


    <!-- component -->
    <div
      :class="modalVisible ? '' : 'hidden'"

      class="h-screen w-full flex flex-col top-0 items-center bg-blue bg-opacity-25 fixed z-40 w-full h-full justify-center bg-teal-lightest font-sans"
    >
      <div
        class="w-full absolute bg-modal mx-auto max-h-screen "
      >
        <div

          class="bg-white rounded overflow-y-scroll max-h-screen mx-auto shadow p-8 m-4 max-w-lg max-h-full text-center"
        >
          <div class="mb-4">
            <h1  v-if="!emailError" class="text-xl sm:text-xl block w-full text-indigo text-left appearance-none mt-4">Vielen Dank für Ihre Schadensmeldung. </h1>
            <h1  v-if="emailError" class="text-xl text-red sm:text-xl block w-full text-indigo text-left appearance-none mt-4">Leider konnte die E-Mail nicht verarbeitet werden.
            </h1>

            <h1 v-if="emailError" class="text-xl text-red sm:text-xl block w-full text-blue text-left appearance-none mt-4">Bitte melden Sie sich bei uns unter: gewaehrleistung@meihoff.de und fügen Sie den unten stehenden Error Log an.</h1>
            <div v-if="emailError" >
              <hr class="mt-8">
              <strong class="text-left ml-0 mt-4 block">Error Log</strong>
              <p class="text-left">{{errorLog}}</p>
              <strong class="text-left ml-0 mt-4 block">Betriebssystem</strong>
              <p class="text-left">{{os}}</p>
              <strong class="text-left ml-0 mt-4 block">Browser</strong>
              <p class="text-left">{{browser}}</p>
            </div>
          </div>
          <div v-if="!emailError" class="mb-8">
            <p class="text-left">
            Eine Eingangsbestätigung wurde an Ihre E-Mail Adresse versendet.
            </p>
          </div>
          <div v-if="!emailError" class="flex justify-center">
            <button
            v-on:click="refresh()"
            class="justify-center px-4 py-3 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-light mx-auto block"

            >
              Schliessen
            </button>
          </div>
          <div v-if="emailError" class="flex justify-center">
            <button
            @click="handleModal"
            class="justify-center px-4 py-3 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-light mx-auto block"

            >
              Schliessen
            </button>
          </div>
        </div>
      </div>
    </div>

    <img
      alt="Meihoff Gewährleistung"
      class="ml-10 mr-auto pt-10 mb-16 relative z-20"
      src="../assets/meihoff-logo.png"
    />

    <div class="mt-10 sm:mt-0 z-20 relative">
      <div
        style="background: #00263f; height:calc(100% - 5rem)"
        class="absolute w-full h-full top-28 left-0"
      ></div>
      <div class="px-4 sm:px-0 max-w-3xl mx-auto relative">
        <form enctype="multipart/form-data" @submit="checkform" action="" class="mx-auto">
          <div class="shadow-2xl overflow-hidden">
            <div
              class="px-4 py-5 bg-white sm:p-12 sm:pb-24 text-left shadow-md relative"
            >
              <h1 class="text-3xl pt-8 mb-2 text-left leading-1">
                Herzlich Willkommen im Mangelmanagement der Meihoff Real Estate
                GmbH
              </h1>
              <p class="mt-2 text-sm">
                Bitte beachten Sie, dass dieses Tool nur zur Erfassung von Bau-
                und Gewährleistungsmängeln dienen soll. Andere Schadensmeldungen
                sind gemäß den Vorgaben der jeweils zuständigen Hausverwaltungen
                zu behandeln. Weiterhin weisen wir darauf hin, dass Ihnen als
                Meldendem Folgekosten einer Untersuchung, die durch
                unberechtigte Mangelanzeigen entstehen, nachträglich in Rechnung
                gestellt werden können. Dieser Untersuchung können sie innerhalb
                von 24 Stunden nach abschicken dieses Formulars jederzeit
                widersprechen.
              </p>
              <p class="mt-2 text-sm">
                Zur strukturierten Erfassung und zeitnahen, weiteren Bearbeitung
                würden wir Sie bitten, Ihren Mangel im nachfolgenden Formular zu
                erfassen. Nach Versenden des Formulars erhalten Sie im Anschluß
                automatisch eine Kopie der hier angegeben Informationen an die
                von Ihnen im Formular eingetragene e-Mailadresse.
              </p>
              <p class="mt-2 mb-4 text-sm">
                Für eine reibungslose Bearbeitung des von Ihnen gemeldeten
                Mangels ist eine detaillierte Beschreibung und Bebilderung
                hilfreich. Gerne würden wir Sie bitten die abgefragten
                Informationen unten möglichst vollständig und detailliert
                auszufüllen und über die Möglichkeit Bilder hochzuladen zu
                bebildern.
              </p>
              <div class="grid grid-cols-6 gap-3">
                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left appearance-none mt-4"
                  >
                    Kurzbeschreibung des Mangels*
                  </h3>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <input
                    v-model="v$.title.$model"
                    type="text"
                    name="title"
                    id="title"
                    :class="v$.title.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6"></div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left mt-4"
                  >
                    Beschreibung des Mangels
                  </h3>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="beschreibung"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Beschreibung*</label
                  >
                  <textarea
                    type="text"
                    :class="v$.beschreibung.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-model="v$.beschreibung.$model"
                    name="beschreibung"
                    id="beschreibung"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  ></textarea>
                </div>

                <div class="col-span-6 sm:col-span-6 relative" @dragover.prevent @drop.prevent>
                  <label
                    for="file"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Fotos</label
                  >
  <div v-if="isUploading" style="height:calc(100% - 1.5em)" class="absolute top-6 bg-blue w-full h-full z-40 bg-opacity-80 flex items-center justify-center rounded">
    <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
  </div>
                  <div  @dragover.prevent @drop.prevent
                    class="mt-1 flex justify-center  px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md"
                    :class="v$.resizedImg.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                  >
                  <div @drop="dragFile" class="p-8 flex items-center justify-center absolute w-full h-full  -top-4 left-0 z-20 mt-10" >

  </div>
                  <div class="space-y-1 text-center relative flex flex-col items-center justify-center w-64 z-20">

                      <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div class="flex text-xs text-gray-600 ">

                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                          <span>Bild hochladen</span>
                          <input
                            id="file-upload"
                            @change="onFileUpload"
                            v-on:change="v$.resizedImg.$model"
                            name="file-upload"
                            multiple
                            type="file"
                            class="sr-only"

                          />
                        </label>
                      </div>
                      <p class="text-xs text-gray-500">
                        oder per drag and drop hinzufügen.<br>
                        PNG, JPG, GIF bis zu 6 Bilder
                      </p>

                    </div>

                    <div class="preview flex items-start w-full relative flex-wrap">

                      <figure v-for="(img, index) in convertedImg" :key="img.id"   class="flex w-20 relative m-2 bg-gray-100 items-center justify-center">
                          <a  v-on:click="clearImages(index)" class="absolute  text-blue-light -top-2 -right-2 bg-gray-200 rounded-full w-8 flex items-center justify-center h-8 pointer 0 z-20">X</a>
                          <img class="object-cover w-full mx-auto"  :src="img" />
                      </figure>
                      <!-- <figure v-for="file in fileURL"  :key="file.id" class="flex w-20">
                          <img class="object-cover w-full"  :src="file" />
                      </figure> -->
                    </div>

                  </div>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <label
                    for="raum"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Raumbezeichnung*</label
                  >
                  <input
                    v-model="v$.raum.$model"
                    type="text"
                    name="raum"
                    id="raum"
                    :class="v$.raum.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Auswirkung des Mangels
                  </h3>
                </div>
                <div class="col-span-6 sm:col-span-6">
                  <textarea
                    type="text"
                    name="auswirkung"
                    v-model="auswirkung"
                    id="auswirkung"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  ></textarea>
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Angaben zum Objekt
                  </h3>
                </div>
                <div class="col-span-6">
                  <label
                    for="bezeichnung"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Bezeichnung*</label
                  >
                  <input
                    v-model="v$.objektbezeichnung.$model"
                    type="text"
                    :class="v$.objektbezeichnung.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    name="bezeichnung"
                    id="bezeichnung"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="objekt-straße"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Straße*</label
                  >
                  <input
                    type="text"
                    v-model="v$.objektstrasse.$model"
                    :class="v$.objektstrasse.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    name="objekt-straße"
                    id="objekt-straße"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="objekt-nr"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Hausnummer*</label
                  >
                  <input
                    type="text"
                    name="objekt-nr"
                    v-model="v$.objekthausnummer.$model"
                    :class="v$.objekthausnummer.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    id="objekt-nr"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="objekt-plz"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >PLZ*</label
                  >
                  <input
                    type="number"
                    name="objekt-plz"
                    id="objekt-plz"
                    :class="v$.objektplz.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-model="v$.objektplz.$model"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="objekt-ort"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Ort*</label
                  >
                  <input
                    type="text"
                    name="objekt-ort"
                    id="objekt-ort"
                    v-model="v$.objektort.$model"
                    :class="v$.objektplz.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Gemeldet von
                  </h3>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="vorname"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Vorname*</label
                  >
                  <input
                    type="text"
                    name="vorname"
                    id="vorname"
                    :class="v$.meldervorname.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-model="v$.meldervorname.$model"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="nachname"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Nachname*</label
                  >
                  <input
                    type="text"
                    name="nachname"
                    id="nachname"
                    :class="v$.meldernachname.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-model="v$.meldernachname.$model"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="anrede"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Anrede*</label
                  >
                  <select
                    id="anrede"
                    name="anrede"
                    v-model="v$.melderanrede.$model"
                    :class="v$.melderanrede.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-light focus:border-indigo-light sm:text-xs"
                  >
                    <option disabled selected value></option>
                    <option>Frau</option>
                    <option>Herr</option>
                    <option>Divers</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="rechtsform"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Privatkunde / Firma*</label
                  >
                  <select
                    id="rechtsform"
                    name="rechtsform"
                    v-model="v$.melderrechtsform.$model"
                    :class="v$.melderrechtsform.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-on:change="selectRechtsform"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-light focus:border-indigo-light sm:text-xs"
                  >
                    <option>Privat</option>
                    <option>Firma</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-6" v-if="rechtsformFirma">
                  <label
                    for="firma"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Name der Firma</label
                  >
                  <input
                    type="text"
                    v-model="melderfirma"
                    name="firma"
                    id="firma"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-5">
                  <label
                    for="melder-straße"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Straße*</label
                  >
                  <input
                    type="text"
                    name="melder-straße"
                    v-model="v$.melderstrasse.$model"
                    :class="v$.melderstrasse.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    id="melder-straße"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>
                <div class="col-span-6 sm:col-span-1">
                  <label
                    for="melder-nr"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Hausnummer*</label
                  >
                  <input
                    type="text"
                    name="melder-nr"
                    id="melder-nr"
                    v-model="v$.melderhausnummer.$model"
                    :class="v$.melderhausnummer.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-1">
                  <label
                    for="melder-plz"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >PLZ*</label
                  >
                  <input
                    type="number"
                    name="melder-plz"
                    id="melder-plz"
                    v-model="v$.melderplz.$model"
                    :class="v$.melderplz.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-5">
                  <label
                    for="melder-ort"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Ort*</label
                  >
                  <input
                    type="text"
                    name="melder-ort"
                    id="melder-ort"
                    v-model="v$.melderort.$model"
                    :class="v$.melderort.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="melder-telefon"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Telefon*</label
                  >
                  <input
                    type="number"
                    name="melder-telefon"
                    id="melder-telefon"
                    v-model="v$.meldertelefon.$model"
                    :class="v$.meldertelefon.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="melder-mail"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >E-Mail*</label
                  >
                  <input
                    type="text"
                    name="melder-mail"
                    id="melder-mail"
                    v-model="v$.melderemail.$model"
                    :class="v$.melderemail.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <label
                    for="melder-zuständigkeit"
                    class="block text-xs text-left text-gray-400 uppercase font-semibold mb-2"
                    >Zuständigkeit*</label
                  >
                  <select
                    id="melder-zuständigkeit"
                    name="melder-zuständigkeit"
                    v-model="v$.melderzustaendigkeit.$model"
                    :class="v$.melderzustaendigkeit.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-on:change="selectZustaendigkeit"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-light focus:border-indigo-light sm:text-xs"
                  >
                    <option>Eigentümer</option>
                    <option>Mieter</option>
                    <option>Hausverwaltung</option>
                    <option>Hausmeister</option>
                    <option>Bauträger</option>
                    <option>Sonstiges</option>
                  </select>
                </div>

                <div
                  class="col-span-6 sm:col-span-6"
                  v-if="zustaendigkeitSonstiges"
                >
                  <input
                    type="text"
                    placeholder="sonstiges"
                    v-model="melderzustaendigkeitsonstiges"
                    name="melder-sonstiges"
                    id="melder-sonstiges"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Angaben zur Nutzung des Objekts bei Auftreten des Mangels*
                  </h3>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <select
                    id="nutzung"
                    name="nutzung"
                    v-model="v$.nutzung.$model"
                    :class="v$.nutzung.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-light focus:border-indigo-light sm:text-xs"
                  >
                    <option>Eigennutzung</option>
                    <option>Dauervermietung</option>
                    <option>Kurzfristige Vermietung z.B. Feriengäste</option>
                    <option>Leerstand</option>
                  </select>
                </div>

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Festgestellt bei*
                  </h3>
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <select
                    id="festgestellt"
                    name="festgestellt"
                    v-model="v$.festgestellt.$model"
                    :class="v$.festgestellt.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                    v-on:change="selectFestgestellt"
                    class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-light focus:border-indigo-light sm:text-xs"
                  >
                    <option>laufendem Betrieb</option>
                    <option>Reparaturarbeiten</option>
                    <option>Wartungsarbeiten</option>
                    <option>Sonstiges</option>
                  </select>
                </div>
                <div
                  class="col-span-6 sm:col-span-6"
                  v-if="feststellungSonstiges"
                >
                  <input
                    placeholder="sonstiges"
                    type="text"
                    v-model="festgestelltsonstiges"
                    name="festgestellt-sonstiges"
                    id="festgestellt-sonstiges"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <input
                  type="text"
                  class="noeyes"
                  name="subject"
                  style="display: none"
                  value=""
                />

                <div class="col-span-6 sm:col-span-6">
                  <h3
                    class="text-xl sm:text-xl block w-full text-indigo text-left"
                  >
                    Gemeldet am*
                  </h3>
                </div>

                <div class="col-span-6 sm:col-span-6 hidden">
                  <input
                    type="text"
                    v-model="important"
                    name="important"
                    id="important"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>

                <div class="col-span-6 sm:col-span-3">
                  <input
                    type="date"
                    v-model="mydate"
                    name="datum"
                    id="datum"
                    class="mt-1 focus:ring-indigo-light focus:border-indigo-light block w-full shadow-sm sm:text-xs border-gray-300 rounded-md"
                  />
                </div>
                <div class="flex mt-6 col-span-6">
                  <label class="flex items-center">
                    <input
                      type="checkbox"
                      v-model="v$.privacy.$model"
                      :class="v$.privacy.$errors[0] ? 'border-solid border-1 border-red-400' : ''"
                      class="form-checkbox bg-white text-indigo"
                    />
                    <span class="ml-2 text-sm"
                      >Ich stimme der     <a target="_blank" href="https://meihoff.de/impressum" class="underline">Datenschutzerklärung</a> zu*
</span>
                  </label>

                </div>
              </div>
              <p class="block text-xs mt-8">Hinweis: Felder mit * müssen ausgefüllt werden.</p>

            </div>
            <div class="px-4 py-8 bg-white text-right sm:px-6">
              <p v-if="v$.$error" class="text-red-500 font-semibold mb-4 text-center opacity-100">Bitte füllen Sie alle markierten Felder aus</p>
              <button
                class="justify-center px-4 py-3 border border-transparent shadow-sm text-xs font-medium rounded-md text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-light mx-auto block"
              >

                Absenden
                  <svg v-if="isSending"  class="animate-spin ml-2 inline h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>


              </button>
            </div>
          </div>
        </form>

        <div class="grid grid-cols-1 sm:grid-cols-2  border-opacity-20 border-black  border-b-2  py-8">
            <div class="w-auto">    <h3
                  class="text-xl sm:text-xl block w-full text-indigo text-left appearance-none"
                >Unser Standort</h3></div>
          <div class="text-left text-white flex mt-4 sm:mt-0 sm:items-end sm:justify-end flex-col">
    <p>Meihoff Real Estate GmbH</p>
  <p>Weißenburgerstr. 58</p>
    <p class="mb-4">40476 Düsseldorf</p>
    <a href="mailto:gewaehrleistung@meihoff.de" style="color:white!important" >gewaehrleistung@meihoff.de</a>
      <p>+49 (0) 211 - 48 46 77 360</p>
            </div>
        </div>
        <footer class="footer cf" role="contentinfo">
  <div class="mt-8">

    <p class="footer-copyright inline text-white mr-2">© 2021 meihoff.de</p>

    <a class="text-white inline " style="color:white!important" href="https://meihoff.de/impressum">Impressum und Datenschutz</a>

  </div>
</footer>
      </div>
    </div>
  </div>
</template>

<script>

import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import axios from 'axios'

export default {
  setup () {
  return { v$: useVuelidate() }
  },
  data(){
    return{
      rechtsformFirma: false,
      zustaendigkeitSonstiges: false,
      validationError: false,
      feststellungSonstiges: false,
      emailError: false,
      errorLog: '',
      uploads: [],
      showSucess: false,
      mydate: '',
      os: '',
      browser: '',
      isUploading: false,
      auswirkung: '',
      beschreibung: '',
      isSending: false,
      resizedImg: [],
      convertedImg: [],
      raum: '',
      title: '',
      objektbezeichnung: '',
      objektstrasse: '',
      modalVisible: false,
      objekthausnummer: '',
      objektort: '',
      objektplz: '',
      meldervorname: '',
      meldernachname: '',
      melderanrede: '',
      melderrechtsform:'',
      melderfirma: '',
      melderstrasse: '',
      melderhausnummer: '',
      melderplz: '',
      melderort: '',
      meldertelefon: '',
      melderemail: '',
      melderzustaendigkeit:'',
      melderzustaendigkeitsonstiges: '',
      nutzung: '',
      festgestellt: '',
      festgestelltsonstiges: '',
      privacy: false,
      test:{
        wurst:["hallo", "kekse"],
        nase:"lol"
      }

    }
  },
  validations() {
    return {
      beschreibung: {
        required
      },
      title:{
        required
      },
      raum: {
        required
      },
      objektbezeichnung: {
        required
      },
      objektstrasse: {
        required
      },
      objekthausnummer: {
        required
      },
      resizedImg:{

      },
      objektort:{
        required
      },
      objektplz: {
        required
      },
      meldervorname: {
        required
      },
      meldernachname: {
        required
      },
      melderanrede: {
        required
      },
      melderrechtsform: {
        required
      },
      // melderfirma: {
      //   required
      // },
      melderstrasse: {
        required
      },
      melderhausnummer: {
        required
      },
      melderplz: {
        required
      },
      melderort: {
        required
      },
      meldertelefon: {
        required
      },
      melderemail: {
        required
      },
      melderzustaendigkeit: {
        required
      },
      // melderzustaendigkeitsonstiges: {
      //   required
      // },
      nutzung: {
        required
      },
      festgestellt: {
        required
      },
      // festgestelltsonstiges: {
      //   required
      // },
      privacy: {
        checked: value => value === true
      },
    }
  },
  methods:{
    callFunction: function(){
      var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,'-');
      this.mydate = currentDateWithFormat;
    },
    refresh: function(){
      location.reload();
    },
    clearImages(id){
      this.resizedImg.splice(id,1);
      this.convertedImg.splice(id,1);

    },
    dragFile(e){
      this.onFileUpload(e.dataTransfer.files, e.type);
    },
    onFileUpload(e,type) {

      var files ;
    if(type == "drop"){
       files = e;
    }
    else{
       files = e.target.files;
    }


      if(files.length >= 6 || this.convertedImg.length >= 6){
        alert("Bitte nicht mehr als 6 Fotos");
      }
      else{
        for (let i=0; i<files.length; i++) {
          var file = files[i];
           if (!file.type.match(/image.*/)) {
             alert("Bitte nur Bilder in den Formaten jpg, png oder gif hochladen.");
             return;
           }
           else {
                    this.isUploading = true;
             resizeImage({ file: file, maxSize: 1024 }).then((resizedImage) => {

               this.resizedImg.push(URL.createObjectURL(resizedImage));
               let vm = this;
                var base64Str;

                var imgHandler = document.getElementById('imgHandler');
                var preview = document.createElement("img");
                var myCanvas = document.createElement("canvas");
                myCanvas.id = "canvas";
                imgHandler.appendChild(preview);
                imgHandler.appendChild(myCanvas);

                 preview.setAttribute('src', URL.createObjectURL(resizedImage));
                 preview.onload = function(){

                   var myCanvas = document.getElementById('canvas');
                   var ctx = myCanvas.getContext('2d');
                   ctx.canvas.width  = "1024";
                   ctx.canvas.height = "768";

                   var hRatio = myCanvas.width / preview.width    ;
                    var vRatio = myCanvas.height / preview.height  ;
                    var ratio  = Math.min ( hRatio, vRatio );
                    ctx.drawImage(preview, 0,0, preview.width, preview.height, 0,0,preview.width*ratio, preview.height*ratio);

                   base64Str = myCanvas.toDataURL();
                   vm.convertedImg.push(base64Str);
                   // myCanvas.remove();
                   // preview.remove();
                  vm.isUploading = false;
                }

             }).catch((err) => {
               console.error(err);
             });
           }
         }
      }


    },
    getOS() {
      var userAgent = window.navigator.userAgent,
          platform = window.navigator.platform,
          macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
          windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
          iosPlatforms = ['iPhone', 'iPad', 'iPod'],
          os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    },
    handleModal(){
      this.modalVisible = false;
    },
    fnBrowserDetect(){

           let userAgent = navigator.userAgent;
           let browserName;

           if(userAgent.match(/chrome|chromium|crios/i)){
               browserName = "chrome";
             }else if(userAgent.match(/firefox|fxios/i)){
               browserName = "firefox";
             }  else if(userAgent.match(/safari/i)){
               browserName = "safari";
             }else if(userAgent.match(/opr\//i)){
               browserName = "opera";
             } else if(userAgent.match(/edg/i)){
               browserName = "edge";
             }else{
               browserName="No browser detection";
             }
           return browserName
    },
    checkform () {
      event.preventDefault();
      // this.$v.touch()
       this.v$.$validate();
       const bodyFormData = new FormData();
       bodyFormData.append('formData',JSON.stringify(this.$data));

      // var queryString = querystring.stringify(this.test);
      // console.log(queryString);

      var v = this;

       if (!this.v$.$error) {
         if(v.important == undefined) {

         this.validationError = false;
         this.isSending = true;
         axios
           .post("https://www.einfach.es/important/meihoff-upload.php", bodyFormData)
           .then(response => {

             console.log(response);
             v.isSending = false;
             if(response.data.sent){
               v.modalVisible = true;
               v.emailError = false;
             }
             else{
               v.os = this.getOS();
               v.browser = this.fnBrowserDetect();
               v.modalVisible = true;
               v.errorLog = response.data.message;
               v.emailError = true;
             }

            })
            .catch(error => {
              v.modalVisible = true;
              v.isSending = false;
              v.emailError = true;
              v.os = this.getOS();
              v.browser = this.fnBrowserDetect();
              v.errorLog = error.response.data;
            });
          }
          } else {
            this.validationError = true;

        }
    },
    selectRechtsform: function(event){
      var key = event.target.value;
      key == 'Firma' ? this.rechtsformFirma = true : this.rechtsformFirma = false
    },
    selectZustaendigkeit: function(event){
      var key = event.target.value;
      key == 'Sonstiges' ? this.zustaendigkeitSonstiges = true : this.zustaendigkeitSonstiges = false
    },
    selectFestgestellt: function(event){
      var key = event.target.value;
      key == 'Sonstiges' ? this.feststellungSonstiges = true : this.feststellungSonstiges = false
    },
  },
  mounted () {
    this.callFunction()
  }
}

// If you like - you can move this section into separate file
// ------- Move from here -------
const dataURItoBlob = (dataURI) => {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL('image/jpeg');

    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
